body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f8ff;
  min-height: 100vh;
  margin: 0;
  padding: 20px;
  overflow-y: auto;
  transition: background-color 4s ease;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  transition: background-color 4s ease, color 4s ease, box-shadow 4s ease;
}

.title,
.resource,
.cell,
.recipe-card,
.market-card,
.quest-card,
.recipe-ingredients,
.recipe-output,
.market-ingredients,
.market-price,
.resource-group,
.resource-group h3,
.label,
.growth-progress,
.has-resources,
.missing-resources,
.weather-tooltip,
.recipe-ingredients span, 
.recipe-output span,
.quest-rewards,
.crafting-section,
.quest-section {
  transition: all 1s ease;
}

.title {
  font-size: 2em;
  color: #2e8b57;
  margin-bottom: 20px;
}

.resources {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 200px));
  gap: 15px;
  margin: 20px 0;
  width: 100%;
  justify-content: center;
}

.resource {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 6px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.85em;
  background: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  max-width: 100%;
}

.dark-mode .resource {
  background: rgba(0, 0, 0, 0.2);
}

.grid {
  display: grid;
  grid-gap: 0 5px;
  max-width: 100%;
}

.row {
  display: flex;
}

.cell {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

.cell.placeable {
  background-color: #90EE90;
  cursor: pointer;
}

.cell.structure {
  background-color: #e8f5e9;
  cursor: pointer;
  transition: transform 0.2s;
}

.cell.structure:hover {
  transform: scale(1.1);
}

.cell.empty {
  background-color: #f5f5f5;
}

.cell.growing {
  background-color: #f0f4c3;
}

.cell.grown {
  background-color: #dcedc8;
}

.structure-indicator {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 100;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.game-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1em;
}

.game-button:hover {
  background-color: #45a049;
}

.game-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.stats {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: #666;
}

.upgrades {
  margin-top: 20px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 8px;
}

.upgrade-button {
  margin: 5px;
  padding: 8px 16px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.upgrade-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.upgrade-button.purchased {
  background: #2E7D32;
}

.upgrade-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.prestige-section {
  position: static;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.prestige-section h2 {
  margin: 0;
  color: #4CAF50;
  font-size: 1.5em;
}

.prestige-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
  margin: 10px 0;
}

.prestige-stat {
  background: white;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
}

.prestige-stat-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #4CAF50;
}

.prestige-section button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.prestige-section button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.prestige-section button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.prestige-section.can-prestige {
  animation: pulse 2s infinite;
}

.prestige-section.can-prestige button {
  background: linear-gradient(45deg, #4CAF50, #45a049);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.achievement-unlocked {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #4CAF50;
  color: white;
  padding: 15px;
  border-radius: 8px;
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.resource[title*="gem"] {
  background: linear-gradient(45deg, #e1f5fe, #b3e5fc);
  animation: sparkle 2s infinite;
}

@keyframes sparkle {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.crafting-section, .quest-section {
  width: 90%;
  margin: 20px 0;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}

.recipe-grid, .quest-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 10px;
}

.recipe-card, .quest-card {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.recipe-card:hover {
  transform: translateY(-2px);
}

.recipe-ingredients, .recipe-output {
  display: flex;
  gap: 10px;
  margin: 5px 0;
}

.quest-rewards {
  color: #4CAF50;
  font-weight: bold;
  margin-top: 10px;
}

.quest-tier {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #4CAF50;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.recipe-card, .quest-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recipe-ingredients, .recipe-output {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
  padding: 5px;
  background: #f8f8f8;
  border-radius: 4px;
}

.recipe-ingredients span, .recipe-output span {
  background: #e0e0e0;
  padding: 3px 8px;
  border-radius: 4px;
}

.quest-rewards {
  margin-top: auto;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.growth-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ddd;
}

.growth-progress-bar {
  height: 100%;
  background: #4CAF50;
  transition: width 0.5s ease;
}

/* Progress bar states */
.progress-0 { width: 0%; }
.progress-10 { width: 10%; }
.progress-20 { width: 20%; }
.progress-30 { width: 30%; }
.progress-40 { width: 40%; }
.progress-50 { width: 50%; }
.progress-60 { width: 60%; }
.progress-70 { width: 70%; }
.progress-80 { width: 80%; }
.progress-90 { width: 90%; }
.progress-100 { width: 100%; }

.growing {
  opacity: 0.5; /* Make unripe wheat appear faded */
}

/* Only show full opacity when growth is complete */
.growing.progress-100 {
  opacity: 1;
}

.resource-group {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  min-width: 0;
  max-width: 92%;
}

.dark-mode .resource-group {
  background: rgba(45, 45, 45, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}

.resource-group h3 {
  margin: 0 0 4px 0;
  color: #4CAF50;
  font-size: 0.9em;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(76, 175, 80, 0.2);
}

.crafting-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.recipe-tier {
  background: #4CAF50;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.8em;
}

.growth-progress-bar {
  width: var(--progress-width);
}

.progress-0 { --progress-width: 0%; }
.progress-10 { --progress-width: 10%; }
.progress-20 { --progress-width: 20%; }
.progress-30 { --progress-width: 30%; }
.progress-40 { --progress-width: 40%; }
.progress-50 { --progress-width: 50%; }
.progress-60 { --progress-width: 60%; }
.progress-70 { --progress-width: 70%; }
.progress-80 { --progress-width: 80%; }
.progress-90 { --progress-width: 90%; }
.progress-100 { --progress-width: 100%; }

.recipe-card, .market-card {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recipe-card:hover, .market-card:hover {
  transform: translateY(-2px);
}

.recipe-card.unavailable, .market-card.unavailable {
  opacity: 0.7;
}

.recipe-ingredients, .recipe-output,
.market-ingredients, .market-price {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background: #f8f8f8;
  border-radius: 4px;
}

.label {
  font-weight: bold;
  color: #666;
  width: 100%;
}

.has-resources {
  color: #2e8b57;
}

.missing-resources {
  color: #d32f2f;
}

.craft-button, .sell-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: auto;
}

.craft-button:hover:not(:disabled),
.sell-button:hover:not(:disabled) {
  background: #45a049;
}

.craft-button:disabled,
.sell-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.market-card {
  border: 1px solid #ffd700;
}

.price {
  color: #ffa000;
  font-weight: bold;
}

.sell-button {
  background: #ffa000;
}

.sell-button:hover:not(:disabled) {
  background: #ff8f00;
}

.floating-notification {
  position: fixed;
  font-size: 1.2em;
  font-weight: bold;
  pointer-events: none;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s, transform 0.5s;
  z-index: 9999;
}

@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

/* Add color-specific styles */
.floating-notification[style*="FF4444"] {
  /* Red notifications (resource loss) */
  text-shadow: 2px 2px 2px rgba(255, 0, 0, 0.2);
}

.floating-notification[style*="4CAF50"] {
  /* Green notifications (resource gain) */
  text-shadow: 2px 2px 2px rgba(0, 255, 0, 0.2);
}

/* Dark mode styles */
body.dark-mode {
  background-color: #1a1a1a;
}

.dark-mode .app-container {
  background: #2d2d2d;
  color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.dark-mode .title {
  color: #4CAF50;
}

.dark-mode .resource {
  background: #3d3d3d;
  color: #e0e0e0;
}

.dark-mode .cell {
  color: #e0e0e0;
}

.dark-mode .cell.empty {
  background-color: #404040;
}

.dark-mode .cell.growing {
  background-color: #2d3b1f;
}

.dark-mode .cell.structure {
  background-color: #2d392e;
}

.dark-mode .recipe-card,
.dark-mode .market-card,
.dark-mode .quest-card {
  background: #3d3d3d;
  color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.dark-mode .recipe-ingredients,
.dark-mode .recipe-output,
.dark-mode .market-ingredients,
.dark-mode .market-price {
  background: #333333;
}

.dark-mode .resource-group {
  background: #333333;
}

.dark-mode .resource-group h3 {
  color: #4CAF50;
}

/* Theme toggle button */
.theme-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.theme-toggle:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

.dark-mode .theme-toggle {
  color: #e0e0e0;
}

/* Dark mode styles (add these to the existing dark mode section) */
.dark-mode .crafting-section,
.dark-mode .quest-section {
  background: #333333;
  color: #e0e0e0;
}

.dark-mode .recipe-ingredients span, 
.dark-mode .recipe-output span {
  background: #4a4a4a;
  color: #e0e0e0;
}

.dark-mode .quest-rewards {
  color: #81c784; /* Lighter green for better contrast in dark mode */
  border-top-color: #404040;
}

.dark-mode .label {
  color: #aaaaaa;
}

.dark-mode .growth-progress {
  background: #404040;
}

.dark-mode .has-resources {
  color: #81c784; /* Lighter green for better contrast */
}

.dark-mode .missing-resources {
  color: #ef5350; /* Lighter red for better contrast */
}

.dark-mode .market-card {
  border-color: #ffd700;
}

.dark-mode .price {
  color: #ffb74d; /* Lighter orange for better contrast */
}

.dark-mode .quest-tier,
.dark-mode .recipe-tier {
  background: #388e3c; /* Slightly darker green for better contrast */
}

.weather-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}
@media (max-width: 768px) {
  .weather-container {
    top: 10px;
    right: 10px;
  }
}
.weather-emoji {
  font-size: 2em;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.weather-tooltip {
  position: absolute;
  top: 100%; /* Position below the weather emoji */
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  font-size: 0.9em;
  width: max-content;
  height: max-content;
  display: none;
  margin-top: 8px;
}

.weather-container:hover .weather-tooltip {
  display: block;
}

.dark-mode .weather-tooltip {
  background: rgba(45, 45, 45, 0.95);
  color: #e0e0e0;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .weather-container {
    top: 10px;
    right: 10px;
  }
  
  .weather-emoji {
    font-size: 1.8em;
  }
}

/* Media queries for mobile */
@media (max-width: 768px) {
  .resources {
    grid-template-columns: repeat(2, minmax(100px, 120px));
  }

  .weather-container {
    top: 10px;
    right: 10px;
  }
}

.recipe-card.locked {
  opacity: 0.7;
  background-color: #f0f0f0;
  border: 1px dashed #999;
}

.recipe-card .requirements {
  color: #ff4444;
  font-size: 0.9em;
  margin-bottom: 8px;
}

.resource-item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 2px 0;
}

.has-resources {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.missing-resources {
  color: #FF4444;
  background-color: rgba(255, 68, 68, 0.1);
}

.has-requirement {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 4px;
}

.missing-requirement {
  color: #FF4444;
  background-color: rgba(255, 68, 68, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 4px;
}

.inventory-count {
  font-size: 0.9em;
  opacity: 0.8;
  margin-left: 4px;
}

.requirements {
  margin-bottom: 8px;
  font-size: 0.9em;
}

.mill-dropdown {
  position: fixed;
  background: white;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.mill-content {
  padding: 12px;
}

.mill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.mill-header h4 {
  margin: 0;
  color: #2e8b57;
}

.close-mill {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 4px;
  color: #666;
}

.mill-stats {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 0.9em;
}

.mill-resources {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: #e8f5e9;
  border-radius: 6px;
  margin: 12px 0;
  font-size: 0.95em;
}

.mill-resources > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mill-resources > div > div:first-child {
  color: #666;
  font-size: 0.9em;
}

.mill-resources > div > div:last-child {
  font-weight: bold;
  color: #2e8b57;
}

.dark-mode .mill-resources {
  background: #1b5e20;
}

.dark-mode .mill-resources > div > div:first-child {
  color: #aaa;
}

.dark-mode .mill-resources > div > div:last-child {
  color: #81c784;
}

.mill-progress-bar {
  height: 6px;
  background: #eee;
  border-radius: 3px;
  margin: 12px 0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #4CAF50;
  transition: width 0.5s linear;
}

.mill-buttons {
  display: flex;
  gap: 8px;
}

.mill-toggle, .mill-upgrade {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.mill-toggle {
  background: #2196F3;
  color: white;
}

.mill-toggle.active {
  background: #f44336;
}

.mill-upgrade {
  background: #4CAF50;
  color: white;
}

.mill-upgrade:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Dark mode support */
.dark-mode .mill-dropdown {
  background: #2d2d2d;
  color: #e0e0e0;
}

.dark-mode .mill-stats,
.dark-mode .mill-resources {
  background: #3d3d3d;
}

.dark-mode .mill-progress-bar {
  background: #404040;
}

.dark-mode .close-mill {
  color: #aaa;
}

/* General styling improvements */
.app-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 30px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.resource-group {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.dark-mode .resource-group {
  background: rgba(45, 45, 45, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}

.resource {
  padding: 4px 8px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
  background: rgba(255, 255, 255, 0.5);
}

.dark-mode .resource {
  background: rgba(0, 0, 0, 0.2);
}

.resource:hover {
  transform: translateX(5px);
}

.game-button {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.game-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.game-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Improve grid cell styling */
.cell {
  border-radius: 8px;
  margin: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

.cell:hover {
  transform: scale(1.1);
  z-index: 1;
}

.dark-mode .cell {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Update app container for better mobile support */
.app-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  width: calc(100% - 40px); /* Account for padding */
}

/* Make resource groups more mobile-friendly */
.resources {
  display: grid;
  grid-template-columns: repeat(4, minmax(120px, 1fr));
  gap: 8px;
  margin: 20px 0;
  width: 100%;
}

.resource-group {
  min-width: 0;
  width: 100%;
}

/* Update grid for mobile */
.grid {
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
}

/* Make recipe and market grids responsive */
.recipe-grid, .quest-grid {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 10px;
  padding: 10px;
}

/* Update buttons for mobile */
.buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  width: 100%;
  margin: 15px 0;
}

.game-button {
  width: 100%;
  min-height: 44px; /* Better touch targets */
  padding: 8px 16px;
}

/* Move prestige section to bottom of screen on mobile */
.prestige-section {
  position: static; /* Remove fixed positioning */
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.prestige-section button {
  background: white;
  color: #4CAF50;
  font-weight: bold;
}

.prestige-section.can-prestige {
  background: linear-gradient(45deg, #4CAF50, #45a049);
}

.dark-mode .prestige-section {
  background: #388e3c;
}

.dark-mode .prestige-section button {
  background: #2d2d2d;
  color: #e0e0e0;
}

/* Media queries for mobile optimization */
@media (max-width: 768px) {
  body {
    padding: 10px;
  }

  .app-container {
    padding: 15px;
    margin: 10px auto;
  }

  .title {
    font-size: 1.5em;
  }

  .resource {
    font-size: 0.9em;
    padding: 8px;
  }

  .cell {
    width: 30px;
    height: 30px;
  }

  .recipe-card, .market-card, .quest-card {
    font-size: 0.9em;
  }

  .crafting-section, .quest-section {
    width: 100%;
    padding: 10px;
  }

  /* Stack resource groups vertically on very small screens */
  @media (max-width: 480px) {
    .resources {
      grid-template-columns: 1fr;
    }

    .buttons {
      grid-template-columns: 1fr 1fr;
    }

    .prestige-section {
      right: 10px;
      bottom: 10px;
      font-size: 0.9em;
    }
  }
}

/* Improve touch targets */
.resource, .game-button, .craft-button, .sell-button {
  min-height: 44px;
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Prevent text selection on interactive elements */
.game-button, .resource, .cell {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}
/* Make tooltips work better on mobile */
.weather-tooltip {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 10px;
}

/* Update resource groups layout */
.resources {
  display: grid;
  grid-template-columns: repeat(4, minmax(120px, 1fr));
  gap: 8px;
  margin: 20px 0;
  width: 100%;
}

.resource-group {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  min-width: 0;
}

.dark-mode .resource-group {
  background: rgba(45, 45, 45, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}

.resource-group h3 {
  margin: 0 0 8px 0;
  color: #4CAF50;
  font-size: 1em;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(76, 175, 80, 0.2);
}

.resource {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
  background: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}

.dark-mode .resource {
  background: rgba(0, 0, 0, 0.2);
}

/* Media queries for better mobile layout */
@media (max-width: 768px) {
  .resources {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .resources {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }

  .resource {
    font-size: 0.85em;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .resources {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }

  .resource {
    padding: 2px 4px;
    font-size: 0.8em;
  }
}

/* For extra small screens */
@media (max-width: 360px) {
  .resources {
    grid-template-columns: 1fr;
  }
}

/* Ensure resource groups don't get too wide on large screens */
@media (min-width: 1200px) {
  .resources {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .resources {
    grid-template-columns: repeat(2, minmax(100px, 100%));
  }
}

.save-load-container {
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.save-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.save-files {
  max-height: 200px;
  overflow-y: auto;
}

.save-file {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.save-file button {
  padding: 4px 8px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.save-file button:first-of-type {
  background: #2196F3;
  color: white;
}

.save-file button:last-of-type {
  background: #f44336;
  color: white;
}

.cell {
  cursor: pointer;
  user-select: none;
}

.cell:hover {
  transform: scale(1.1);
  transition: transform 0.1s ease;
}

.save-load-menu {
  position: static;
  top: 10px;
  right: 10px;
  background: var(--background-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  z-index: 1000;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.save-button {
  width: 100%;
  padding: 10px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 10px;
  transition: background-color 0.2s;
}

.save-button:hover {
  background: var(--primary-color-dark);
}

.save-files {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
}

.save-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background: var(--background-primary);
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.save-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.save-name {
  font-weight: bold;
  color: var(--text-primary);
}

.save-date {
  font-size: 0.8em;
  color: var(--text-secondary);
}

.save-actions {
  display: flex;
  gap: 8px;
}

.save-actions button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s;
}

.load-button {
  background: var(--success-color);
  color: white;
}

.load-button:hover {
  background: var(--success-color-dark);
}

.delete-button {
  background: var(--danger-color);
  color: white;
}

.delete-button:hover {
  background: var(--danger-color-dark);
}

/* Add these CSS variables to your :root */
:root {
  --primary-color: #4CAF50;
  --primary-color-dark: #388E3C;
  --success-color: #2196F3;
  --success-color-dark: #1976D2;
  --danger-color: #f44336;
  --danger-color-dark: #d32f2f;
  --background-primary: #ffffff;
  --background-secondary: #f5f5f5;
  --text-primary: #333333;
  --text-secondary: #666666;
  --border-color: #dddddd;
}

/* Dark mode variables */
.dark-mode {
  --background-primary: #2d2d2d;
  --background-secondary: #1f1f1f;
  --text-primary: #ffffff;
  --text-secondary: #bbbbbb;
  --border-color: #404040;
}

/* Add some responsive styles */
@media (max-width: 768px) {
  .save-load-menu {
    width: calc(100% - 40px);
    margin: 10px;
    right: 0;
  }
}

.save-load-section {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.save-load-section h2 {
  margin: 0;
  color: #4CAF50;
  font-size: 1.5em;
}

.save-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.save-files {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  background: white;
  border-radius: 6px;
}

.save-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #f8f8f8;
  border-radius: 6px;
  border: 1px solid #eee;
}

.save-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.save-name {
  font-weight: bold;
  color: #333;
}

.save-date {
  font-size: 0.8em;
  color: #666;
}

.save-actions {
  display: flex;
  gap: 8px;
}

.save-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
}

.load-button {
  background: #2196F3;
  color: white;
}

.load-button:hover {
  background: #1976D2;
}

.delete-button {
  background: #f44336;
  color: white;
}

.delete-button:hover {
  background: #d32f2f;
}

/* Dark mode support */
.dark-mode .save-load-section {
  background: #2d2d2d;
}

.dark-mode .save-files {
  background: #333;
}

.dark-mode .save-file {
  background: #3d3d3d;
  border-color: #444;
}

.dark-mode .save-name {
  color: #fff;
}

.dark-mode .save-date {
  color: #aaa;
}

/* Empty state */
.save-files:empty::after {
  content: "No saves yet";
  display: block;
  text-align: center;
  padding: 20px;
  color: #666;
}

.dark-mode .save-files:empty::after {
  color: #aaa;
}

.grid-container {
  position: relative;
}